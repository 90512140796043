import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatNumber } from "../services/Helper.js";

const BalanceCard = ({ network, balance, symbol }) => {
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [rate, setRate] = useState({
    eth: 0,
    bnb: 0,
  });

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price",
          {
            params: {
              ids: "binancecoin,ethereum",
              vs_currencies: "usd",
            },
          }
        );

        if (response?.data) {
          setRate({
            eth: response?.data?.ethereum?.usd || 0,
            bnb: response?.data?.binancecoin?.usd || 0,
          });
        }
      } catch (error) {
        console.error("Error fetching cryptocurrency rates:", error);
      }
    };

    fetchRates();
  }, []);

  useEffect(() => {
    const convertCurrency = () => {
      if (network && network?.symbol) {
        const rateValue = rate[network?.symbol.toLowerCase()];

        if (rateValue) {
          const result = balance * rateValue;
          setConvertedAmount(result);
        } else {
          console.warn(`Rate not found for symbol: ${network?.symbol}`);
        }
      }
    };

    convertCurrency();
  }, [balance, network, rate]);

  return (
    <>
      <div className="usd-box">
        <h3
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {formatNumber(balance, 5)} {symbol}
          <img
            src={network?.icon}
            alt="Bitcoin Icon"
            style={{ width: "24px", height: "24px", marginLeft: "10px" }}
          />
        </h3>
        <p>${formatNumber(convertedAmount, 3)} USD</p>{" "}
      </div>
    </>
  );
};

export default BalanceCard;
