import React from "react";

const Splash = () => {
  return (
    <>
      <div className="extensions-box">
        <div className="splash-box">
          <div className="splash-logo">
            <a>
              <img src="images/logo.png" alt="logo" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
