import React, { useState } from "react";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Head from "../../components/Head";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState({});

  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [wrongPassMsg, setWrongPassMsg] = useState("");

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!currentPassword) {
      errors.currentPassword = "Old Password cannot be empty.";
    } else if (!newPassword) {
      errors.newPassword = "New Password cannot be empty.";
    } else if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password cannot be empty.";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/.test(
        newPassword
      )
    ) {
      errors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword =
        "Confirm Password do not match with new Password.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/users/change-user-password`,
          {
            currentPassword: currentPassword,
            newPassword: newPassword,
            confirmPassword: confirmPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          console.log("password changed");
          localStorage.removeItem("token");
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error resetting password:", error);
          setWrongPassMsg("Old password not matched with existing Password.");
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  //show password

  const toggleOldPasswordVisibility = () => {
    setOldPasswordVisible(!oldPasswordVisible);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <a href="#" onClick={() => handleNavigate("/account")}>
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </a>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="send-crypto-des">
              <h2>Change Password</h2>
              <div className="form-group">
                <label>Old Password</label>
                <div className="form-group-inner password-fild-box">
                  <div className="fild-icon">
                    <i className="ti ti-lock" />
                  </div>
                  <input
                    type={oldPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter password"
                    name="currentPassword"
                    value={currentPassword}
                    onChange={handleCurrentPassword}
                  />
                  {errors.currentPassword && (
                    <small className="form-text text-danger">
                      {errors.currentPassword}
                    </small>
                  )}
                  <div
                    className="eye-view-btn"
                    onClick={toggleOldPasswordVisibility}
                  >
                    <i
                      className={`ti ${
                        oldPasswordVisible ? "ti-eye-off" : "ti-eye"
                      }`}
                    />
                  </div>
                  <small className="form-text text-danger">
                    {wrongPassMsg}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label>New Password</label>
                <div className="form-group-inner password-fild-box">
                  <div className="fild-icon">
                    <i className="ti ti-lock" />
                  </div>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    className="form-control"
                    name="newPassword"
                    value={newPassword}
                    onChange={handleNewPassword}
                    placeholder="New Password"
                  />
                  {errors.newPassword && (
                    <small className="form-text text-danger">
                      {errors.newPassword}
                    </small>
                  )}
                  <div
                    className="eye-view-btn"
                    onClick={togglePasswordVisibility}
                  >
                    <i
                      className={`ti ${
                        passwordVisible ? "ti-eye-off" : "ti-eye"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Confirm New Password</label>
                <div className="form-group-inner password-fild-box">
                  <div className="fild-icon">
                    <i className="ti ti-lock" />
                  </div>
                  <input
                    type={confirmPasswordVisible ? "text" : "password"}
                    className="form-control"
                    placeholder="Enter Confirm password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                  />
                  {errors.confirmPassword && (
                    <small className="form-text text-danger">
                      {errors.confirmPassword}
                    </small>
                  )}
                  <div
                    className="eye-view-btn"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <i
                      className={`ti ${
                        confirmPasswordVisible ? "ti-eye-off" : "ti-eye"
                      }`}
                    />
                  </div>
                </div>
              </div>
              <div className="form-submit-btn">
                <button className="btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
