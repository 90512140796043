import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const validatePassword = (password) => {
    const errors = {};
    const passwordCriteria = {
      length: /.{8,}/,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /[0-9]/,
      symbol: /[!@#$%^&*(),.?":{}|<>]/,
    };

    if (!passwordCriteria.length.test(password)) {
      errors.password = "Password must be at least 8 characters.";
    } else if (!passwordCriteria.uppercase.test(password)) {
      errors.password = "Password must contain at least one uppercase letter.";
    } else if (!passwordCriteria.lowercase.test(password)) {
      errors.password = "Password must contain at least one lowercase letter.";
    } else if (!passwordCriteria.number.test(password)) {
      errors.password = "Password must contain at least one number.";
    } else if (!passwordCriteria.symbol.test(password)) {
      errors.password = "Password must contain at least one symbol.";
    } else {
      errors.password = ""; // No errors
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      const validationErrors = validatePassword(value);
      setErrors({ ...errors, password: validationErrors.password });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validatePassword(formData.password);
    if (validationErrors.password) {
      setErrors({ ...errors, password: validationErrors.password });
    } else if (formData.password !== formData.confirmPassword) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match." });
    } else {
      setErrors({ ...errors, confirmPassword: "" });
      // Proceed with form submission
      console.log("Form submitted with token:", token);

      try {
        const requestBody = {
          verificationToken: token,
          newPassword: formData.password,
          confirmPassword: formData.confirmPassword,
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/reset-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();

        if (response.ok) {
          console.log("result message : ", result.message);
          alert(result.message);
          navigate("/login");
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="extensions-box">
        <div className="extensions-header">
          <Link to="/forget-password">
            <img src="images/back.png" alt="back" />
          </Link>
        </div>
        <div className="form-content-box">
          <div className="form-logo">
            <Link to="/" href="#">
              <img src="images/logo.png" alt="logo" />
            </Link>
          </div>
          <h2>Reset Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>New Password</label>
              <div className="form-group-inner password-fild-box">
                <div className="fild-icon">
                  <i className="ti ti-lock" />
                </div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Enter Password"
                />
                <div
                  className="eye-view-btn"
                  onClick={() => {
                    setPasswordVisible(!passwordVisible);
                  }}
                >
                  <i
                    className={`ti ${
                      !passwordVisible ? "ti-eye-off" : "ti-eye"
                    }`}
                  />
                </div>
              </div>

              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div className="form-group">
              <label>Confirm New Password</label>
              <div className="form-group-inner password-fild-box">
                <div className="fild-icon">
                  <i className="ti ti-lock" />
                </div>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Confirm Password"
                />
                <div
                  className="eye-view-btn"
                  onClick={() => {
                    setConfirmPasswordVisible(!confirmPasswordVisible);
                  }}
                >
                  <i
                    className={`ti ${
                      !confirmPasswordVisible ? "ti-eye-off" : "ti-eye"
                    }`}
                  />
                </div>
              </div>

              {errors.confirmPassword && (
                <div className="error">{errors.confirmPassword}</div>
              )}
            </div>
            <div className="form-submit-btn">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
