// import { createSlice } from "@reduxjs/toolkit";

// export const networkSlice = createSlice({
//   name: "network",
//   initialState: {
//     chainId: "11155111",
//     explorerURL: "https://sepolia-testnet-explorer.com",
//     icon: "images/crypto-icon2.png",
//     name: "Sepolia",
//     networkName: "Sepolia Testnet",
//     rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
//     symbol: "ETH",
//   },
//   reducers: {
//     updateNetwork: (state, action) => {
//       return { ...state, ...action.payload };
//     },
//   },
// });

// // Action creators are generated for each case reducer function
// export const { updateNetwork } = networkSlice.actions;

// export default networkSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState =
  typeof window !== "undefined" && localStorage.getItem("selectedCrypto")
    ? JSON.parse(localStorage.getItem("selectedCrypto"))
    : null;

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    updateNetwork: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      if (typeof window !== "undefined") {
        localStorage.setItem("selectedCrypto", JSON.stringify(updatedState));
      }
      return updatedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateNetwork } = networkSlice.actions;

export default networkSlice.reducer;
