import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Head from "../components/Head";
import { ethers } from "ethers";
import { Loader } from "../components/Loader/Loader"; // Make sure this is the correct path to your Loader component
import {
  getERC20Balance,
  getNativeTokenBalance,
  NATIVE,
} from "../services/Helper";

const SendCrypto2 = () => {
  const [receiverAddress, setReceiverAddress] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [errors, setErrors] = useState({ address: "", amount: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  console.log("data is RRR: ", data);

  useEffect(() => {
    if (!data) {
      navigate("/"); // Redirect to home page if no data is provided
    }
  }, []);

  const validateAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const validateAmount = (amount) => {
    return parseFloat(amount) > 0;
  };

  const handleTransfer = async () => {
    let addressError = "";
    let amountError = "";

    if (!validateAddress(receiverAddress)) {
      addressError = "Invalid wallet address";
    }

    if (!validateAmount(inputAmount)) {
      amountError = "Amount must be valid";
    }
    let senderWallet = localStorage.getItem("publicKey");
    let userBalance = 0;
    setLoading(true);

    if (data?.contractAddress === NATIVE) {
      userBalance = await getNativeTokenBalance(senderWallet);
    } else {
      userBalance = await getERC20Balance(
        data?.contractAddress,
        localStorage.getItem("publicKey")
      );
    }

    console.log("userBalance : ", userBalance);
    if (parseFloat(inputAmount) >= userBalance) {
      amountError = "Insufficient balance for this transfer";
      // return;
    }

    setErrors({ address: addressError, amount: amountError });

    if (addressError || amountError) {
      setLoading(false);
      return;
    }

    try {
      // Simulate a delay for the transfer process
      await new Promise((resolve) => setTimeout(resolve, 2000));

      let updatedData = {
        ...data, // Spread the existing data object properties
        receiverAddress: receiverAddress,
        inputAmount: inputAmount,
      };

      navigate("/crypto-details", {
        state: updatedData,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />} {/* Display the loader when loading is true */}
      <div className="extensions-box">
        <div className="extensions-header">
          <Head />
        </div>
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Send {data?.symbol}</h2>
            <div className="form-group">
              <label>Wallet Address</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-user" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  value={receiverAddress}
                  onChange={(e) => {
                    setReceiverAddress(e.target.value);
                  }}
                  disabled={loading}
                />
              </div>
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            <div className="form-group">
              <div className="form-group-inner">
                <div className="fild-icon eth">{data?.symbol}</div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter amount"
                  value={inputAmount}
                  onChange={(e) => {
                    setInputAmount(e.target.value);
                  }}
                  disabled={loading}
                />
              </div>
              {errors.amount && <p className="error">{errors.amount}</p>}
            </div>
            <div className="form-submit-btn">
              <button
                className="btn"
                onClick={handleTransfer}
                disabled={loading}
              >
                {loading ? "Processing..." : "Transfer"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCrypto2;
