import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Head from "../components/Head";
import ImportToken from "../components/Models/ImportToken.js";
import WalletBalance from "../components/BalanceCard.js";
import { ethers } from "ethers";
import { Loader } from "../components/Loader/Loader"; // Import your Loader component
import axios from "axios";
import { getERC20Balance, NATIVE } from "../services/Helper.js";
import TransactionHistory from "../components/TransactionHistory.js";
import { TokensList } from "../components/TokensList.js";
import { useSelector } from "react-redux";

const Home = () => {
  const selectedNetwork = useSelector((state) => state.network);
  const [balance, setBalance] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [importTokenList, setImportTokenList] = useState([]);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  async function getNativeBalance() {
    console.log("selectedNetwork.rpc : ", selectedNetwork?.rpc);
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        selectedNetwork?.rpc
      );
      const balance = await provider.getBalance(walletAddress);
      const balanceInEther = ethers.utils.formatEther(balance);
      setBalance(balanceInEther);
    } catch (error) {
      console.log("unable to get native balance");
    }
  }

  useEffect(() => {
    if (walletAddress && selectedNetwork) {
      getNativeBalance();
    }
  }, [walletAddress, selectedNetwork]);

  useEffect(() => {
    const wallet = localStorage.getItem("publicKey");
    setWalletAddress(wallet);

    const fetchBalance = async () => {
      setIsLoading(true);
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/tokens/get`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then(async (response) => {
            console.log("9660 : ", response?.data?.tokens);

            const tokenPromises = response?.data?.tokens?.map(async (token) => {
              try {
                const erc20Balance = await getERC20Balance(
                  token?.contractAddress,
                  walletAddress
                );
                return {
                  ...token,
                  erc20Balance: erc20Balance,
                };
              } catch (error) {
                console.error("Error fetching ERC20 balance: ", error);
                return {
                  ...token,
                  erc20Balance: null,
                };
              }
            });

            const tokenListWithBalances = await Promise.all(tokenPromises);
            const updatedTokens = tokenListWithBalances.filter(
              (token) => token.chainId === selectedNetwork.chainId
            );
            setImportTokenList(updatedTokens);
          })
          .catch((error) => {
            console.error("Error fetching ERC20 balance: ", error);
          });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching balance: ", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (walletAddress && selectedNetwork) {
      fetchBalance();
    }
  }, [walletAddress, selectedNetwork, reload]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="extensions-box">
        <Head />

        <div className="home-bg padd20">
          <WalletBalance network={selectedNetwork} balance={balance} />

          <div className="send-receive-btn">
            <button
              className="border-btn"
              onClick={() => {
                let data = {
                  chainId: selectedNetwork?.chainId,
                  contractAddress: NATIVE,
                  decimals: 18,
                  name: selectedNetwork?.name,
                  symbol: selectedNetwork?.symbol,
                };

                navigate("/send-crypto", {
                  state: data,
                });
              }}
            >
              <i className="ti ti-arrow-up-right" />
              <span>Send</span>
            </button>
            <Link to="/receive-crypto" className="border-btn">
              <i className="ti ti-arrow-down-left" />
              <span>Receive</span>
            </Link>
          </div>
          <div className="tokens-transactions-box">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Tokens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Transactions
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <TokensList
                  network={selectedNetwork}
                  balance={balance}
                  importTokenList={importTokenList}
                />

                <div className="import-token-btn">
                  <a href data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <i className="ti ti-download" />
                    <span>Import Token</span>
                  </a>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <TransactionHistory />
              </div>
            </div>
          </div>
        </div>

        <ImportToken setReload={setReload} reload={reload} />
      </div>
    </>
  );
};

export default Home;
