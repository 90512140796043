import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const Registration = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [existEmailMsg, setExistEmailMsg] = useState("");

  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    } else if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    } else if (!password) {
      errors.password = "Password cannot be empty.";
    } else if (!confirmPassword) {
      errors.confirmPassword = "Confirm Password cannot be empty.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/.test(
        password
      )
    ) {
      errors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number.";
    } else if (password !== confirmPassword) {
      errors.confirmPassword =
        "Confirm Password do not match with new Password.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/users/add`, {
          name: name,
          email: email,
          password: password,
        })
        .then((response) => {
          navigate("/login");
        })
        .catch((error) => {
          setExistEmailMsg("This email address is exists");
          console.error("Error in signup", error);
        });
    } catch (error) {
      console.log("catch error", error);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <div className="extensions-box">
      <div className="extensions-header">
          <Link to='/login'>
            <img src="images/back.png" alt="back" />
          </Link>
        </div>
        <div className="form-content-box">
          <form onSubmit={handleSubmit}>
            <div className="form-logo">
              <Link to='/' href="#">
                <img src="images/logo.png" alt="logo" />
              </Link>
            </div>
            <h2>Welcome Back!</h2>
            <div className="form-group">
              <label>Name</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-user" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  name="name"
                  value={name}
                  onChange={handleNameChange}
                />
                {errors.name && (
                  <small className="form-text text-danger">{errors.name}</small>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Email</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-mail" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  name="email"
                  value={email}
                  onChange={handleEmailChange}
                />
                {errors.email && (
                  <small className="form-text text-danger">
                    {errors.email}
                  </small>
                )}
                <span className="form-text text-danger">{existEmailMsg}</span>
              </div>
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="form-group-inner password-fild-box">
                <div className="fild-icon">
                  <i className="ti ti-lock" />
                </div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Password"
                  name="newPassword"
                  value={password}
                  onChange={handlePassword}
                />
                {errors.password && (
                  <small className="form-text text-danger">
                    {errors.password}
                  </small>
                )}
                <div
                  className="eye-view-btn"
                  onClick={togglePasswordVisibility}
                >
                  <i
                    className={`ti ${
                      passwordVisible ? "ti-eye-off" : "ti-eye"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <div className="form-group-inner password-fild-box">
                <div className="fild-icon">
                  <i className="ti ti-lock" />
                </div>
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Confirm Password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                />
                {errors.confirmPassword && (
                  <small className="form-text text-danger">
                    {errors.confirmPassword}
                  </small>
                )}
                <div
                  className="eye-view-btn"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <i
                    className={`ti ${
                      confirmPasswordVisible ? "ti-eye-off" : "ti-eye"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="form-submit-btn">
              <button className="btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Registration;
