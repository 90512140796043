import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateNetwork } from "../services/redux/networkSlice";
import { useSelector } from "react-redux";
import { getCoinPrice, networkList } from "../services/Helper";
import { setPrice } from "../services/redux/cryptoPriceSlice";

const Head = () => {
  const selectedNetwork = useSelector((state) => state.network);

  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();

  const saveSelectedCrypto = async (crypto) => {
    setSelectedCrypto(crypto);
    localStorage.setItem("selectedCrypto", JSON.stringify(crypto));
    dispatch(updateNetwork(crypto));

    const livePrice = await getCoinPrice();

    dispatch(setPrice(livePrice));
  };

  // useEffect(() => {
  //   const address = JSON.parse(localStorage.getItem("publicKey"));
  //   console.log("EVM is here 899999: ", network.blockchainType);
  //   if (network?.blockchainType === "evm") {
  //     setWalletAddress(address?.evmAddress);
  //   }
  //   if (network?.blockchainType === "tron") {
  //     setWalletAddress(address?.tronAddress);
  //   }

  //   if (network?.blockchainType === "bitcoin") {
  //     setWalletAddress(address?.bitcoinAddress);
  //   }
  // }, [network]);

  useEffect(() => {
    const storedCrypto = localStorage.getItem("selectedCrypto");
    if (storedCrypto) {
      setSelectedCrypto(JSON.parse(storedCrypto));
    } else {
      const defaultCrypto = networkList[0];
      setSelectedCrypto(defaultCrypto);
      localStorage.setItem("selectedCrypto", JSON.stringify(defaultCrypto));
    }
  }, [selectedNetwork]); // Depend on reload state to re-run this effect

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div className="extensions-header">
        <div className="home-header">
          <div className="head-logo">
            <Link to="/">
              <img src="images/logo.png" alt="logo" />
            </Link>
          </div>
          <div
            className="dropdown select-coin-box"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <button
              className="dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedCrypto ? (
                <>
                  <i>
                    <img src={selectedCrypto?.icon} alt="coin" />
                  </i>
                  <span>
                    {selectedCrypto?.name?.length > 5
                      ? selectedCrypto?.name.slice(0, 5) + "..."
                      : selectedCrypto?.name}
                  </span>
                </>
              ) : (
                <>Select Cryptocurrency</>
              )}
            </button>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {showTooltip && selectedCrypto && (
                <div className="tooltipp">
                  <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                    {selectedCrypto?.networkName}
                  </p>

                  <p
                    style={{
                      fontSize: "12px",
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    Chain Id : {selectedCrypto?.chainId}
                  </p>
                </div>
              )}
            </div>

            <div className="dropdown-menu">
              <h3>Select Cryptocurrency</h3>
              <ul>
                {networkList.map((item, key) => (
                  <li key={key}>
                    <a href="#" onClick={() => saveSelectedCrypto(item)}>
                      <figure>
                        <img src={item.icon} alt={`${item.name} icon`} />
                      </figure>
                      <figcaption>{item.name}</figcaption>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="user-image-box">
            <Link to="/account" href="">
              <img src="images/user.png" alt="user" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Head;
