import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Head from "../components/Head";
import { Loader } from "../components/Loader/Loader"; // Make sure this is the correct path to your Loader component
import {
  getERC20Balance,
  getNativeTokenBalance,
  getTronNativeBalance,
  getWalletAddress,
  NATIVE,
  validateBitcoinAddress,
  validateEvmAddress,
  validateTronAddress,
} from "../services/Helper";
import { useSelector } from "react-redux";
import axios from "axios";

const SendCrypto2 = () => {
  const [receiverAddress, setReceiverAddress] = useState("");
  const [inputAmount, setInputAmount] = useState("");
  const [errors, setErrors] = useState({ address: "", amount: "" });
  const [loading, setLoading] = useState(false);
  const [walletAddress, setWalletAddress] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const selectedNetwork = useSelector((state) => state.network);

  async function InitializeWalletAddress() {
    const wallet = await getWalletAddress(selectedNetwork?.blockchainType);
    setWalletAddress(wallet);
  }

  console.log("data : ", data);

  useEffect(() => {
    if (!data) {
      navigate("/"); // Redirect to home page if no data is provided
    }
    InitializeWalletAddress();
  }, [walletAddress]);

  const validateAmount = (amount) => {
    return parseFloat(amount) > 0;
  };

  const handleTransfer = async () => {
    let addressError = "";
    let amountError = "";

    let isValidAddress;
    if (selectedNetwork?.blockchainType === "evm") {
      isValidAddress = await validateEvmAddress(receiverAddress);
    } else if (selectedNetwork?.blockchainType === "tron") {
      isValidAddress = await validateTronAddress(receiverAddress);
    } else {
      isValidAddress = await validateBitcoinAddress(receiverAddress);
    }

    if (!isValidAddress) {
      addressError = `Invalid ${selectedNetwork?.blockchainType} wallet address`;
    }

    if (!validateAmount(inputAmount)) {
      amountError = "Amount must be valid";
    }
    // return;

    let userBalance = 0;
    setLoading(true);

    if (data?.contractAddress === NATIVE) {
      if (selectedNetwork?.blockchainType === "evm") {
        userBalance = await getNativeTokenBalance(data, walletAddress);
      } else if (selectedNetwork?.blockchain === "tron") {
        let output = await getTronNativeBalance(walletAddress);
        if (output?.data?.status === "success") {
          userBalance = parseFloat(output?.data?.balance);
        } else {
          amountError = "Error getting Tron native balance";
          setLoading(false);
          return;
        }
      } else {
        // Bitcoin wallet validation

        const url = `${process.env.REACT_APP_API_URL}/users/bitcoin-balance`;
        // Use await to return the balance directly
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        // Ensure balance is returned
        userBalance = response?.data?.balance?.data?.incoming ?? 0;
      }
    } else {
      userBalance = await getERC20Balance(data?.contractAddress, walletAddress);
    }

    console.log("userBalance : ", userBalance);
    if (parseFloat(inputAmount) >= userBalance) {
      amountError = "Insufficient balance for this transfer";
    }

    setErrors({ address: addressError, amount: amountError });

    if (addressError || amountError) {
      setLoading(false);
      return;
    }

    try {
      // Simulate a delay for the transfer process
      await new Promise((resolve) => setTimeout(resolve, 2000));

      let updatedData = {
        ...data, // Spread the existing data object properties
        receiverAddress: receiverAddress,
        inputAmount: inputAmount,
      };

      navigate("/crypto-details", {
        state: updatedData,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />} {/* Display the loader when loading is true */}
      <div className="extensions-box">
        <div className="extensions-header">
          <Head />
        </div>
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Send {data?.symbol}</h2>
            <div className="form-group">
              <label>Wallet Address</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-user" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Address"
                  value={receiverAddress}
                  onChange={(e) => {
                    setReceiverAddress(e.target.value);
                  }}
                  disabled={loading}
                />
              </div>
              {errors.address && <p className="error">{errors.address}</p>}
            </div>
            <div className="form-group">
              <div className="form-group-inner">
                <div className="fild-icon eth">{data?.symbol}</div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter amount"
                  value={inputAmount}
                  onChange={(e) => {
                    setInputAmount(e.target.value);
                  }}
                  disabled={loading}
                />
              </div>
              {errors.amount && <p className="error">{errors.amount}</p>}
            </div>
            <div className="form-submit-btn">
              <button
                className="btn"
                onClick={handleTransfer}
                disabled={loading}
              >
                {loading ? "Processing..." : "Transfer"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCrypto2;
