import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Head from "../components/Head";
import {
  addressFormat,
  estimateGasFee,
  evmCryptoTransfer,
} from "../services/Helper";
import WalletBalance from "../components/BalanceCard";
import axios from "axios";
import { Loader } from "../components/Loader/Loader";

const ConfirmTxDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gasFee, setGasFee] = useState(0);
  const [network, setNetwork] = useState({});
  const [walletAddress, setWalletAddress] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [rate, setRate] = useState({
    eth: 0,
    bnb: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");

  const data = location.state;
  console.log("data from Crypto Details : ", data);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price",
          {
            params: {
              ids: "binancecoin,ethereum,usdt",
              vs_currencies: "usd",
            },
          }
        );
        console.log("USDT API : ", response);

        if (response?.data) {
          setRate({
            eth: response?.data?.ethereum?.usd || 0,
            bnb: response?.data?.binancecoin?.usd || 0,
          });
        }
      } catch (error) {
        console.error("Error fetching cryptocurrency rates:", error);
      }
    };

    fetchRates();
  }, []);

  useEffect(() => {
    const wallet = localStorage.getItem("publicKey");
    setWalletAddress(wallet);

    const fetchBalance = async () => {
      try {
        const localStorageNetwork = JSON.parse(
          localStorage.getItem("selectedCrypto")
        );
        setNetwork(localStorageNetwork);

        const gas = await estimateGasFee(
          data?.receiverAddress,
          data?.inputAmount
        );
        setGasFee(gas);
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    const calculateTotalAmount = () => {
      const gasInEther = Number(gasFee) / 10 ** 18;
      const total = gasInEther + parseFloat(data?.inputAmount);
      setTotalAmount(total);
    };

    if (gasFee && data?.inputAmount) {
      calculateTotalAmount();
    }
  }, [gasFee]);

  useEffect(() => {
    const convertCurrency = async () => {
      if (network && network?.symbol) {
        const rateValue = rate[network.symbol.toLowerCase()];
        if (rateValue) {
          const result = totalAmount * rateValue;
          setConvertedAmount(result);
        } else {
          console.warn(`Rate not found for symbol: ${network?.symbol}`);
        }
      }
    };

    if (totalAmount && network.symbol) {
      convertCurrency();
    }
  }, [totalAmount, network.symbol, rate]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    closeModal();

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/check-password`,
        {
          password: password.trim(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.data?.data?.evmWallet?.privateKey) {
        const privateKey = response?.data?.data?.evmWallet?.privateKey;
        const result = await evmCryptoTransfer(
          privateKey,
          data?.contractAddress,
          data?.receiverAddress,
          data?.inputAmount
        );
        console.log("Result:", result);
        if (result?.transactionHash) {
          const apiInput = {
            chainId: data?.chainId.toString(),
            sender: walletAddress,
            receiver: data?.receiverAddress,
            amount: Number(data?.inputAmount),
            crypto: data?.symbol,
            hash: result?.transactionHash,
            tag: `${data?.symbol} Transfer`,
          };
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/transactions/add`,
              apiInput,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            console.log("Transaction added:", response);
          } catch (e) {
            console.error("Error adding transaction:", e);
            alert("Internal server error");
          }

          navigate("/");
        } else {
          alert("Transfer Failed");
          navigate("/");
        }
      } else {
        alert("Password not matched");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Password not matched");
    } finally {
      setLoading(false);
    }
  };

  const sendToken = () => {
    openModal();
  };

  return (
    <>
      {loading && <Loader />}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/send-crypto">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Confirm Details</h2>

            <WalletBalance
              network={network}
              balance={data?.inputAmount}
              symbol={data?.symbol}
            />

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfering Fund</h5>
                </li>

                <li>
                  <h5>From</h5>
                  <p>
                    <span>To</span>
                  </p>
                </li>

                <li>
                  <h5>{addressFormat(walletAddress, 5)}</h5>
                  <p>
                    <span>{addressFormat(data?.receiverAddress, 5)}</span>
                  </p>
                </li>
              </ul>
            </div>

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfer</h5>
                  <p>
                    <span>
                      {data?.inputAmount} {data?.symbol} |
                    </span>{" "}
                    ${convertedAmount.toFixed(2)} USD
                  </p>
                </li>
                <li>
                  <h5>Fees</h5>
                  <p>
                    <span>
                      {(Number(gasFee) / 10 ** 18).toFixed(6)} {data?.symbol} |
                    </span>{" "}
                    $
                    {(
                      (convertedAmount / totalAmount) *
                      (Number(gasFee) / 10 ** 18)
                    ).toFixed(2)}{" "}
                    USD
                  </p>
                </li>
                <li>
                  <h5>Total</h5>
                  <p>
                    <span>
                      {totalAmount.toFixed(6)} {data?.symbol} | $
                      {convertedAmount.toFixed(2)} USD
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="form-submit-btn">
              <button className="btn" onClick={sendToken}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Password Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="send-crypto-des">
          <h2>Login Password</h2>
        </div>

        <form onSubmit={handlePasswordSubmit}>
          <div className="form-group">
            <div className="form-group-inner">
              <input
                type="password"
                value={password}
                className="form-control"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
              />
            </div>
            {/* {errors.amount && <p className="error">{errors.amount}</p>} */}
          </div>

          <div className="form-submit-btn">
            <button
              className="btn"
              // onClick={handleTransfer}
              disabled={loading}
              type="submit"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ConfirmTxDetails;
