import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [seedPhrases, setSeedPhrases] = useState(Array(12).fill(""));
  const [errors, setErrors] = useState({ email: "", seedPhrases: "" });

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    let valid = true;
    let emailError = "";
    let seedPhrasesError = "";

    if (!validateEmail(email)) {
      emailError = "Please enter a valid email address.";
      valid = false;
    }

    if (seedPhrases.some((phrase) => phrase.trim() === "")) {
      seedPhrasesError = "Please fill out all seed phrases.";
      valid = false;
    }

    setErrors({ email: emailError, seedPhrases: seedPhrasesError });
    return valid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSeedPhraseChange = (index, value) => {
    const newSeedPhrases = [...seedPhrases];
    newSeedPhrases[index] = value;
    setSeedPhrases(newSeedPhrases);
  };

  const handleSubmit = async (e) => {
    // navigate("/reset-password?token=08cea3d4-e5c6-4aa4-8404-3faec9182479");
    e.preventDefault(); // Prevent form submission if validation fails
    if (validateForm()) {
      const seedPhrasesString = seedPhrases.join(" ");
      const requestBody = {
        email: email,
        phrase: seedPhrasesString,
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/verify-user-mail`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );
        const result = await response.json();

        if (response.ok) {
          console.log("result : ", result);
          const verificationUrl = result.verificationUrl;
          navigate(verificationUrl);
        } else {
          if (result.message === "Email not found") {
            setErrors({ email: result.message });
          } else {
            setErrors({ seedPhrases: result.message });
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <div className="extensions-box">
        <div className="extensions-header">
          <Link to="/login">
            <img src="images/back.png" alt="back" />
          </Link>
        </div>
        <div className="form-content-box">
          <div className="form-logo">
            <Link to="/" href="#">
              <img src="images/logo.png" alt="logo" />
            </Link>
          </div>
          <h2>Forgot Password</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-mail" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div className="seed-phrases-box">
              <div className="seed-phrases-head">
                <h3>Seed Phrases</h3>
                <p>Enter phrases in correct order</p>
              </div>
              <div className="row">
                {Array.from({ length: 12 }).map((_, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="form-group">
                      <div className="form-group-inner">
                        <div className="fild-icon">{index + 1}</div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Word"
                          value={seedPhrases[index]}
                          onChange={(e) =>
                            handleSeedPhraseChange(index, e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {errors.seedPhrases && (
                <div className="error">{errors.seedPhrases}</div>
              )}
            </div>
            <div className="form-submit-btn">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
