import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Home from "./pages/Home";
import SecureStorage from "./pages/AccountPages/SecureStorage";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Registration from "./pages/Registration";
import HasToken from "./pages/auth/HasToken";
import PrivateRoute from "./pages/auth/PrivateRoute";
import MyProfile from "./pages/AccountPages/MyProfile";
import ReceiveCrypto from "./pages/ReceiveCrypto";
import SendCrypto from "./pages/SendCrypto";
import Splash from "./pages/Splash";
import Account from "./pages/AccountPages/AccountMain";
import ChangePassword from "./pages/AccountPages/ChangePassword";
import ConfirmTxDetails from "./pages/ConfirmTxDetails";
import CustomerSupport from "./pages/AccountPages/CustomerSupport";
import ResetPassword from "./pages/ResetPassword";

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  if (showSplash) {
    return <Splash />;
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/splash" element={<Splash />} />

        <Route
          path="/signup"
          element={
            <HasToken>
              <Registration />
            </HasToken>
          }
        />

        <Route
          path="/login"
          element={
            <HasToken>
              <Login />
            </HasToken>
          }
        />

        <Route
          path="/forget-password"
          element={
            <HasToken>
              <ForgotPassword />
            </HasToken>
          }
        />

        <Route
          path="/reset-password"
          element={
            <HasToken>
              <ResetPassword />
            </HasToken>
          }
        />

        {/* private routes */}

        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />

        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />

        <Route
          path="/secure-storage"
          element={
            <PrivateRoute>
              <SecureStorage />
            </PrivateRoute>
          }
        />

        <Route
          path="/my-profile"
          element={
            <PrivateRoute>
              <MyProfile />
            </PrivateRoute>
          }
        />

        <Route
          path="/receive-crypto"
          element={
            <PrivateRoute>
              <ReceiveCrypto />
            </PrivateRoute>
          }
        />

        <Route
          path="/send-crypto"
          element={
            <PrivateRoute>
              <SendCrypto />
            </PrivateRoute>
          }
        />

        <Route
          path="/crypto-details"
          element={
            <PrivateRoute>
              <ConfirmTxDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/customer-support"
          element={
            <PrivateRoute>
              <CustomerSupport />
            </PrivateRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
