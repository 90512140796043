import { ethers } from "ethers";
import { TokenABI } from "./TokenABI";

export const NATIVE = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const formatNumber = (input, decimalPlace) => {
  let str = input.toString();
  let dotIndex = str.indexOf(".");
  if (dotIndex === -1) {
    return str;
  }
  let preDot = str.substring(0, dotIndex + 1);
  let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);

  return preDot + postDot;
};

export async function getContractData(tokenContractAddress) {
  const data = {
    error: null,
    status: "fail",
    info: {
      name: null,
      decimal: null,
      symbol: null,
    },
  };
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
    console.log("network -----------> ", network);
    console.log("777777 ------------------> ", provider);
    const copperContract = new ethers.Contract(
      tokenContractAddress,
      TokenABI,
      provider
    );

    data.info.name = await copperContract.name();
    data.info.decimal = await copperContract.decimals();
    data.info.symbol = await copperContract.symbol();
    data.status = "success";

    return data;
  } catch (e) {
    console.log(e);
    data.error = "Invalid input data";
    return data;
  }
}

export async function evmCryptoTransfer(
  privateKey,
  tokenAddress,
  receiverAddress,
  amount
) {
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
    console.log("network -----------> ", network);
    console.log("777777 ------------------> ", provider);

    const wallet = new ethers.Wallet(
      // "34ea57c812f20bfe5aa62f68aed959ae1338cec162400e2d5678cefe25204112",
      privateKey,
      provider
    );

    if (tokenAddress === NATIVE) {
      console.log("Transfering native token");
      const tx = {
        to: receiverAddress,
        value: ethers.utils.parseEther(amount.toString()),
      };

      const transactionResponse = await wallet.sendTransaction(tx);
      console.log(`Transaction hash: ${transactionResponse.hash}`);

      // Wait for the transaction to be mined
      const receipt = await transactionResponse.wait();
      console.log(`Transaction mined in block ${receipt.blockNumber}`);

      return receipt;
    } else {
      const tokenContract = new ethers.Contract(tokenAddress, TokenABI, wallet);
      const AMOUNT = ethers.utils.parseUnits(amount.toString(), 18); // Replace "1.0" with the amount of tokens to transfer and "18" with the token's decimal places
      const tx = await tokenContract.transfer(receiverAddress, AMOUNT);
      console.log(`Transaction hash: ${tx.hash}`);
      const receipt = await tx.wait();
      return receipt;
    }
  } catch (e) {
    console.error("Error in evmCryptoTransfer:", e);
  }
}

// Define transaction details

// Estimate gas
export async function estimateGasFee(receiverAddress, amount) {
  try {
    const tx = {
      to: receiverAddress,
      value: ethers.utils.parseEther(amount.toString()),
    };

    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
    console.log("network -----------> ", network);
    console.log("777777 ------------------> ", provider);

    const gasEstimate = await provider.estimateGas(tx);
    const gasPrice = await provider.getGasPrice();

    // Calculate the total gas fee
    const totalGasFee = gasEstimate.mul(gasPrice);

    return totalGasFee;
  } catch (error) {
    console.error("Error estimating gas fee: ", error);
    return 0;
  }
}

export const addressFormat = (address, charlength) => {
  if (!address || typeof address !== "string") {
    return ""; // Return an empty string if address is null, undefined, or not a string
  }

  if (address.length <= charlength * 2) {
    return address;
  }
  return `${address.substring(0, charlength)}...${address.substring(
    address.length - charlength
  )}`;
};

export async function getERC20Balance(tokenContractAddress, walletAddress) {
  // if (!(tokenContractAddress && walletAddress)) return;
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
    console.log("network -----------> ", network);
    console.log("777777 ------------------> ", provider);
    const contract = new ethers.Contract(
      tokenContractAddress,
      TokenABI,
      provider
    );
    const decimal = await contract.decimals();
    const balance = await contract.balanceOf(walletAddress);

    const parseBalance = Number(balance.toString()) / 10 ** Number(decimal);

    return parseBalance;
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getNativeTokenBalance(walletAddress) {
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const provider = new ethers.providers.JsonRpcProvider(network?.rpc);
    console.log("network -----------> ", network);
    console.log("777777 ------------------> ", provider);
    const balance = await provider.getBalance(walletAddress);
    const parseBalance = ethers.utils.formatEther(balance);
    return parseBalance;
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export function getNetwork(chainId) {
  switch (chainId) {
    case 11155111:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://site1.moralis-nodes.com/sepolia/b9f317ba31f94131be7850d821725543",
        // rpc: `https://eth-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
        // rpc: "https://sepolia.infura.io/v3/",
      };
    case 97:
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://site1.moralis-nodes.com/bsc-testnet/955586d16b7d410f895f51b75e47caf9",
        // rpc: `https://bnb-testnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
        // rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
      };
    // case 43113:
    //   return {
    //     network: "Avalanche",
    //     symbol: "AVAX",
    //     rpc: `https://avax-fuji.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
    //     // rpc: "https://api.avax-test.network/ext/bc/C/rpc",
    //   };
    // case 80002:
    //   return {
    //     network: "Polygon",
    //     symbol: "MATIC",
    //     // rpc: `https://polygon-amoy.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_KEY}`,
    //     // rpc: "https://rpc-amoy.polygon.technology",
    //   };
    default:
      throw new Error("Unsupported chainId");
  }
}

// all chainId and RPC list
export const networkList = [
  {
    name: "Sepolia",
    icon: "images/crypto-icon2.png",
    networkName: "Sepolia Testnet",
    rpc: getNetwork(11155111).rpc,
    chainId: 11155111,
    symbol: "ETH",
    explorerURL: "https://sepolia-testnet-explorer.com",
  },
  {
    name: "Binance Testnet",
    icon: "images/crypto-icon3.png",
    networkName: "Binance Smart Chain Testnet",
    rpc: getNetwork(97).rpc,
    chainId: 97,
    symbol: "BNB",
    explorerURL: "https://testnet.bscscan.com",
  },
  // {
  //   name: "Polygon Matic",
  //   icon: "images/crypto-icon4.png",
  //   networkName: "Polygon Matic",
  //   rpc: getNetwork(80002).rpc,
  //   chainId: 80002,
  //   symbol: "MATIC",
  //   explorerURL: "https://amoy.polygonscan.com/",
  // },
];

export const trimContent = (text, length) => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (text.length <= length) {
    return text;
  } else {
    return text.slice(0, length) + "...";
  }
};
