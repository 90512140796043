import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Head from "../../components/Head";

const CustomerSupport = () => {
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    description: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setUserId(response?.data?.data?._id);
        setFormData({
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
        });
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/customer-support/add`,
          {
            id: userId,
            ...formData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((resp) => {
          setFormData((prevData) => ({
            ...prevData,
            description: "",
          }));
          console.log(resp);
          alert("Support request submitted successfully.");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error updating admin details:", error);
      alert("Failed to update admin details.");
    }
  };

  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/account">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="send-crypto-des">
              <h2>Customer Support</h2>
              <div className="form-group">
                <label>Name</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-user" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    className="form-control"
                    value={formData.name}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-mail" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter email"
                    name="email"
                    className="form-control"
                    value={formData.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-info-circle" />
                  </div>
                  <textarea
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    value={formData.description}
                    onChange={handleInputChange}
                    maxLength={300}
                  />
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>
              </div>
              <div className="form-submit-btn">
                <button className="btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomerSupport;
