import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import Head from "../components/Head";
import {
  addressFormat,
  estimateGasFee,
  evmCryptoTransfer,
  getWalletAddress,
  NATIVE,
} from "../services/Helper";
import WalletBalance from "../components/BalanceCard";
import axios from "axios";
import { Loader } from "../components/Loader/Loader";
import { useSelector } from "react-redux";

const ConfirmTxDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gasFee, setGasFee] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const livePrice = useSelector((state) => state.price);
  const selectedNetwork = useSelector((state) => state.network);

  const data = location.state;

  async function InitializeWalletAddress() {
    const wallet = await getWalletAddress(selectedNetwork?.blockchainType);
    setWalletAddress(wallet);
  }

  useEffect(() => {
    InitializeWalletAddress();

    const fetchBalance = async () => {
      try {
        const gas = await estimateGasFee(
          data?.receiverAddress,
          data?.inputAmount
        );
        setGasFee(gas);
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedNetwork?.blockchainType === "evm") {
      fetchBalance();
    } else {
      setLoading(false);
    }
  }, [walletAddress]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    closeModal();

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/check-password`,
        {
          password: password.trim(),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response?.data?.data?.evmWallet?.privateKey) {
        const privateKey = response?.data?.data?.evmWallet?.privateKey;
        let result = null;
        let blockchainType = selectedNetwork?.blockchainType;

        if (blockchainType === "evm") {
          result = await evmCryptoTransfer(
            privateKey,
            data?.contractAddress,
            data?.receiverAddress,
            data?.inputAmount,
            data?.decimals
          );
          console.log("Result:", result);
          if (result?.transactionHash) {
            const apiInput = {
              chainId: data?.chainId.toString(),
              sender: walletAddress,
              receiver: data?.receiverAddress,
              amount: Number(data?.inputAmount),
              crypto: data?.symbol,
              hash: result?.transactionHash,
              tag: `${data?.symbol} Transfer`,
            };
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/transactions/add`,
                apiInput,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              console.log("Transaction added:", response);
            } catch (e) {
              console.error("Error adding transaction:", e);
              alert("Internal server error");
            }

            navigate("/");
          } else {
            alert("Transfer Failed");
            navigate("/");
          }
        } else if (blockchainType === "tron") {
          try {
            result = await axios.post(
              `${process.env.REACT_APP_API_URL}/users/tron-transfer`,
              {
                contractAddress: data?.contractAddress,
                receiverAddress: data?.receiverAddress,
                amount: data?.inputAmount,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            console.log("result : ", result);
            if (result?.data?.status === "success") {
              const apiInput = {
                chainId: data?.chainId.toString(),
                sender: walletAddress,
                receiver: data?.receiverAddress,
                amount: Number(data?.inputAmount),
                crypto: data?.symbol,
                hash: result?.data?.receipt?.txid,
                tag: `${data?.symbol} Transfer`,
              };
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/transactions/add`,
                  apiInput,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
                console.log("Transaction added:", response);
                alert("Token transferred successfully");
                navigate("/");
              } catch (e) {
                console.error("Error adding transaction:", e);
                alert("Internal server error");
              }
            } else {
              alert("Transfer Failed");
            }
          } catch (error) {
            console.log("Tron network transfer Error : ", error);
          }
        } else {
          // For bitcoin transfer
          try {
            result = await axios.post(
              `${process.env.REACT_APP_API_URL}/users/bitcoin-transfer`,
              {
                receiverAddress: data?.receiverAddress,
                amount: parseFloat(data?.inputAmount),
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );
            console.log("result : ", result);
            if (result?.data?.status === true) {
              console.log(
                "result?.data? : ",
                result?.data,
                result?.data?.txId?.txId
              );

              const apiInput = {
                chainId: "101",
                sender: walletAddress,
                receiver: data?.receiverAddress,
                amount: Number(data?.inputAmount),
                crypto: "BTC",
                hash: result?.data?.txId?.txId,
                tag: `BTC Transfer`,
              };
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/transactions/add`,
                  apiInput,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                );
                console.log("Transaction added:", response);
                alert("Token transferred successfully");
                navigate("/");
              } catch (e) {
                console.error("Error adding transaction:", e);
                alert("Internal server error");
              }
            } else {
              console.log("Error message is : ", result?.data);

              alert("Transfer Failed");
            }
          } catch (error) {
            console.log(
              "Bitcoin network transfer Error : ",
              error?.response?.data?.message
            );
            alert(error?.response?.data?.message);
          }
        }
      } else {
        alert("Password not matched");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Password not matched");
    } finally {
      setLoading(false);
    }
  };

  const sendToken = () => {
    openModal();
  };

  return (
    <>
      {loading && <Loader />}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/send-crypto">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Confirm Details</h2>

            <WalletBalance
              network={selectedNetwork}
              balance={data?.inputAmount}
              symbol={data?.symbol}
              livePrice={livePrice}
              isNative={data?.contractAddress === NATIVE}
            />

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfering Fund</h5>
                </li>

                <li>
                  <h5>From</h5>
                  <p>
                    <span>To</span>
                  </p>
                </li>

                <li>
                  <h5>{addressFormat(walletAddress, 5)}</h5>
                  <p>
                    <span>{addressFormat(data?.receiverAddress, 5)}</span>
                  </p>
                </li>
              </ul>
            </div>

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfer</h5>
                  <p>
                    <span>
                      {data?.inputAmount} {data?.symbol}
                    </span>
                  </p>
                </li>
                {selectedNetwork?.blockchainType === "evm" && (
                  <>
                    <li>
                      <h5>Fees</h5>
                      <p>
                        <span>
                          {(Number(gasFee) / 10 ** 18).toFixed(6)}{" "}
                          {selectedNetwork?.symbol}
                        </span>
                      </p>
                    </li>
                    <li>
                      <h5>Total</h5>
                      <p>
                        <span>
                          {data?.inputAmount} {data?.symbol} +{" "}
                          {(Number(gasFee) / 10 ** 18).toFixed(6)}{" "}
                          {selectedNetwork?.symbol}
                        </span>
                      </p>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="form-submit-btn">
              <button className="btn" onClick={sendToken}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Password Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="send-crypto-des">
          <h2>Login Password</h2>
        </div>

        <form onSubmit={handlePasswordSubmit}>
          <div className="form-group">
            <div className="form-group-inner">
              <input
                type="password"
                value={password}
                className="form-control"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
              />
            </div>
            {/* {errors.amount && <p className="error">{errors.amount}</p>} */}
          </div>

          <div className="form-submit-btn">
            <button
              className="btn"
              // onClick={handleTransfer}
              disabled={loading}
              type="submit"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ConfirmTxDetails;
